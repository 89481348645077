// nuxt-ui/Shared/composables/twitch.ts

import type { TwitchLive } from "~/src/twitch/domain/models/TwitchLive";
import type { TwitchLiveDTO } from "~/src/twitch/infrastructure/DTO/TwitchLiveDTO";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";

interface UseTwitch {
  getTwitchLiveInfo: () => Promise<TwitchLive | void>;
}

export const useTwitch = (): UseTwitch => {
  return {
    getTwitchLiveInfo,
  };

  async function getTwitchLiveInfo(): Promise<TwitchLive | void> {
    try {
      const { region } = useCompetitionStore();

      if (!region) {
        console.error("Region is not defined.");
        return;
      }

      //todo - create world-cup at backend
      const path =
        region.slug === "world-cup" ? "/api/prd/twitch-users/espana" : `/api/prd/twitch-users/${region.slug}`;

      const response = await $fetch<TwitchLiveDTO>(path);

      if (response) {
        return response;
      } else {
        console.error("NOTE: Fetched Twitch live info is null or undefined");
      }
    } catch (e) {
      console.error("An error occurred while fetching Twitch live info:", e);
    }
  }
};
